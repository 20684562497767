import React, { useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import Footer from './Footer';
import './pages.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import Form from './Form';
import { motion } from 'framer-motion';

const Contact = () => {
  useEffect(() => {
    AOS.init({ duration: 2000, offset: 200 ,once: true });
  }, []);

  return (
    <>
      <Navbar />
      <motion.div 
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1 }}
    >

      
      <div className="margin-contact">
      <div data-aos="fade-down" className="grid grid-cols-1 md:grid-cols-3 gap-4">
  <div className="open-hours rounded-lg shadow-md px-8 py-6 card-contact items-center justify-center animate__animated animate__fadeInUp">
    <i className="fa-solid fa-clock clock-icon text-blue-800 text-center"></i>
    <div className="font-sans text-center">
      <h1 className="text-2xl font-semibold">Open Hours</h1>
      <p className="text-gray-700">Mon-Fri: 10:00 AM – 07:00 PM</p>
      <p className="text-gray-700">Sat: 09:30 AM – 07:30 PM</p>
      <p className="text-gray-700">Sunday: Closed</p>
    </div>
  </div>

  <div className="open-hours rounded-lg shadow-md px-8 py-6 card-contact items-center justify-center animate__animated animate__fadeInUp">
    <i className="fa-solid fa-map clock-icon text-blue-800 text-center"></i>
    <div className="font-sans text-center">
      <h1 className="text-2xl font-semibold">Address</h1>
      <p className="text-gray-700">635, R.K. World Tower, Sheetal park, 150ft. ring road, Rajkot - 360006, Gujarat, India.</p>
      {/* <p className="text-gray-700">300330</p>
      <a href="/" className="text-blue-700 hover:underline">Address...</a><br /> */}
    </div>
  </div>

  <div className="open-hours rounded-lg shadow-md px-8 py-6 card-contact items-center justify-center animate__animated animate__fadeInUp">
    <i className="fa-solid fa-phone clock-icon text-blue-800 text-center"></i>
    
    <div className="font-sans text-center">
      <h1 className="text-2xl font-semibold">Get In Touch</h1>
      <p className="text-gray-700">Email : info@belianceweb.com</p>
      <p className="text-gray-700">Phone no : +91 9726411336</p>
      <p className="text-gray-700">Phone no : +91 9624612648</p>
      {/* <p className="text-gray-700">Phone no : +91 9409770083</p> */}
      {/* <a href="#form-link" className="text-blue-700 hover:underline">Contact Form</a><br /> */}
    </div>
  </div>
</div>

      </div>


{/* form */}
      <section id='form-link' className="text-gray-600 body-font bg-white-100">
        <div className="container flex flex-col md:flex-row lg:max-w-5xl w-full px-5 py-2 mx-auto section" id="contact-form">
          <div data-aos="fade-up" className="md:w-1/3 w-full">
            <h1 className="text-4xl text-gray-800 sm:text-4xl font-bold title-font mb-4">Envisioning a product?</h1>
            <p className="leading-relaxed text-xl text-gray-900">We craft custom software solutions designed to captivate your audience, all while ensuring cost-effectiveness. Join us on the journey to elevate your enterprise to new heights.
              <br />
              <br />
              You can also visit us. Click here to reveal address:      <a href='https://maps.app.goo.gl/nCKmkRaAZUV8s3Vj8' className='address-link' target='_blank'>   address</a><br />
              {/* <a href="/" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Address</a> */}
            </p>
            <p className="leading-relaxed text-xl text-gray-900 mt-8">
              635, R.K. World Tower, Sheetal park, 150ft. ring road, Rajkot - 360006, Gujarat, India.
            </p>
          </div>

          <div data-aos="fade-up" className="md:w-2/3 w-full mt-10 md:mt-0 md:pl-28">
            <h1 className="text-4xl text-gray-800 sm:text-4xl font-bold title-font mb-4">Get In Touch</h1>
            <Form/>
          </div>
        </div>
      </section>

    {/* Map */}
      <div data-aos="fade-in" className="responsive-map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4541.59609848799!2d70.76469662599399!3d22.317808542259186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48bc9f6fc910656d%3A0xda672cfe663b7f00!2sBeliance%20Web!5e1!3m2!1sen!2sin!4v1730979909516!5m2!1sen!2sin" 
      width="600"
      height="450"
      title='Map' 
      allowfullscreen="" 
      loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade">

      </iframe>
      </div>

      <Footer />
      </motion.div>
    </>
  );
}

export default Contact;
